<template>
  <el-container class="content-center-middle">
    <el-card>
      <el-row>
        <el-col>
          <el-collapse class="hidden-sm-and-up">
            <el-collapse-item
              v-for="item in $tm('personal.info.contents')"
              :key="item.title"
              :title="item.title"
              :name="item.title"
            >
              <div class="accordion-content info-detail" v-html="item.detail"></div>
            </el-collapse-item>
          </el-collapse>
          
          <el-tabs tab-position="left" class="hidden-xs-only-cus">
            <el-tab-pane
              v-for="tab in $tm('personal.info.contents')"
              :key="tab.title"
              class="tabsContent"
              :label="tab.title"
            >
              <div class="info-detail" v-html="tab.detail" />
            </el-tab-pane>
          </el-tabs>
        </el-col>
      </el-row>
      <div class="steps-button" v-if="hasJWT">
        <el-button type="primary" round @click="onBtnClick">
          {{ $t("common.button.back") }}
        </el-button>
      </div>
    </el-card>
  </el-container>
</template>

<style scoped>
ul.buttons {
  background-color: #ffffff;
}
.content-center-middle {
  display: flex !important;
}
.el-card {
  text-align: left;
  font-size: 20px;
  width: 100%;
  margin: 20px 20px;
}
.el-icon {
  font-size: 12em;
  color: #ffc107; 
}
.el-container {
  display: block;
}
.steps-button {
  margin: 20px 0px;
  text-align: center;
}
.steps-button .el-button {
  width: 100%;
  max-width: 300px;
}

/* Eligibility */
.info-detail :deep(ol){
  counter-reset: list;
  margin: 0;
}

.info-detail :deep(ol > ol){
  list-style-type: lower-alpha;
}

.info-detail :deep(ol ol > li) {
  list-style: none;
	position: relative;
}

.info-detail :deep(ol ol > li:before) {
  counter-increment: list;
  content: counter(list, lower-alpha) ")";
  position: absolute;
  left: -1.4em;
}

/* Application Fee and Payment Method */
.info-detail :deep(table) {
  border: 1px solid #e3e3e3;
  border-collapse: collapse;
}

.info-detail :deep(table) tr {
  border: 1px solid #e3e3e3;
}

.info-detail :deep(td) {
  border: 1px solid #e3e3e3;
  padding: 10px 20px;
}

.info-detail :deep(.el-alert--warning) {
  border-left: 5px solid #e6a23c;
  padding: 8px !important;
}

.info-detail :deep(.el-alert--warning .el-alert__description) {
  color:#11254a;
  margin:auto !important;
  font-size:14px;
}

/* Colour Passport Photo Requirements */
.info-detail :deep(.imgSample) {
  background: url("../../assets/photo_sample.jpg");
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  width:90px;
  height:120px;
}

.info-detail :deep(th) {
  border: 1px solid #e3e3e3;
  padding: 10px 20px;
}

.info-detail :deep(td ul) {
  padding:0px 0px 0px 10px;
}

.hidden-sm-and-up :deep(.el-collapse-item__header) {
    display: flex;
    align-items: center;
    height: var(--el-collapse-header-height);
    line-height: var(--el-collapse-header-height);
    background-color: var(--el-collapse-header-bg-color);
    color: var(--el-collapse-header-text-color);
    cursor: pointer;
    border-bottom: 1px solid var(--el-collapse-border-color);
    font-size: var(--el-collapse-header-font-size);
    font-weight: 500;
    transition: border-bottom-color var(--el-transition-duration);
    outline: 0
}

.hidden-sm-and-up :deep(.el-collapse-item__header.is-active) {
  background-color: #b44357;
  color: #fff;
  text-align: left
}

.hidden-sm-and-up :deep(.el-collapse-item__content) {
    padding-bottom: 25px;
}

</style>

<script>
// import "@/style/common.css"; 
// import { goTo } from "../../libs/common";
import "element-plus/theme-chalk/display.css";

export default {
  name: "PersonalAppInfo",
  methods: {
    onBtnClick() {
      history.back();
      // goTo("StepStart")
    }
  },
  computed: {
    hasJWT() {
      return this.$store.getters.personalJWT
    }
  }
}
</script>